import * as React from "react";
import { Parallax } from "@react-spring/parallax";
import Layout from "@lekoarts/gatsby-theme-cara/src/components/layout";
import Hero from "../components/hero";

import Contact from "../components/contact";

const Cara = () => (
  <Layout>
    <Parallax pages={1.2}>
      <Hero offset={0} factor={1} />
      <Contact offset={0.3} factor={1} />
    </Parallax>
  </Layout>
);

export default Cara;
